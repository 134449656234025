import { render, staticRenderFns } from "./CategoryCard.vue?vue&type=template&id=cbb6f15a&scoped=true&"
import script from "./CategoryCard.vue?vue&type=script&lang=js&"
export * from "./CategoryCard.vue?vue&type=script&lang=js&"
import style0 from "./CategoryCard.vue?vue&type=style&index=0&id=cbb6f15a&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbb6f15a",
  null
  
)

export default component.exports