<template>
  <div
    v-if="!IS_LOADING.status"
    class="guest-homepage flex flex-col w-full bg-white"
    :style="{
      '--bottomSpacing': '4.5rem',
    }"
  >
    <GuestHeader />

    <div>
      <div class="max-w-2xl mx-auto py-20 w-11/12">
        <h2 class="font-bold mb-4 text-3xl text-center text-peach">
          UNSERE ERLEBNISSE:
        </h2>
        <div class="flex flex-wrap gap-2 justify-center">
          <a
            v-for="(category, index) in CATEGORIES"
            :key="`item-${index}`"
            :href="`/vouchers/category/${$helpers.toSlug(category.name)}`"
            class="border border-peach flex font-medium hover:bg-peach hover:text-white items-center px-10 py-3"
          >
            <p>{{ category.name }}</p>
          </a>
        </div>
      </div>

      <div class="overflow-hidden relative">
        <img class="absolute bottom-0 hidden inset-x-0 max-h-full md:block mx-auto object-center object-cover" src="@/_assets/img/home-page-girl-heart-min.jpg">
        <img class="absolute block inset-x-0 max-h-full md:hidden mt-4 mx-auto object-center object-cover top-0" src="@/_assets/img/home-page-girl-hear-mobile-min.jpg">
        <div
          class="content-container flex flex-col header-container items-center lg:py-28 md:pb-60 md:pt-20 pb-60 relative z-1"
        >
          <div class="text-center text-white w-11/12 max-w-2xl">
            <p class="font-bold mb-6 mx-auto text-peach text-center text-logo max-w-sm md:max-w-2xl">
              Tiroler Momente zu Weihnachten
            </p>
            <button @click="$router.push('/vatertag')" class="bg-company company-bg-color font-medium inline-flex items-center px-4 py-2 rounded-md text-white w-64">
              DIE BESTEN MOMENTE ZU ZWEIT ENTDECKEN
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <VouchCategory
        title="JETZT NEU"
        description="Hard Rock Café Innsbruck<br/>Kulinarik & Shopping im Herzen der Tiroler Alpen – das Hard Rock Café Innsbruck bietet ein unvergessliches Erlebnis, mit genialen Speisen, sensationellen Drinks und Live-Events der Extraklasse. Und das Hard Rock Café T-Shirt Innsbruck gehört auch in deinen Kleiderschrank."
        category="Sport & Adventure"
        :ids="section_1"
        :showButton="true"
        :theme="'gray'"
        :preTitle="'Derzeit beliebt:'"
        :isVoucher="true"
      >
        <div class="w-full md:mx-10 md:w-1/2 px-7 md:px-0">
          <VoucherCard
            :voucher="voucher"
          />
        </div>
      </VouchCategory>

      <div class="py-10">
        <div class="content-container w-11/12">
          <h2 class="text-center mb-10 font-semibold md:text-5xl text-2xl text-peach md:text-black">
            So funktioniert epasnets:
          </h2>

          <div class="grid grid-cols-1 md:grid-cols-3 md:gap-32 gap-10">
            <div class="text-center">
              <i class="bi bi-cart text-6xl text-peach"></i>
              <p class="text-peach text-xl my-3">Gutschein aussuchen</p>
              <p class="text-sm">
                Wähle deinen Gutschein aus den unterschiedlichen Kategorien aus und füge ihn zu deiner Wunschliste hinzu oder gehe direkt zum Warenkorb, wo du deinen Kauf ganz einfach über unsere Online-Zahlungsmethoden abschließen kannst.
              </p>
            </div>
            <div class="text-center">
              <i class="bi bi-pencil text-6xl text-peach"></i>
              <p class="text-peach text-xl my-3">Gutschein personalisieren</p>
              <p class="text-sm">
                Gestalte deinen Gutschein mit Fotos, Widmungen oder individuellen Texten und mache deinen Liebsten eine persönliche Freude. Zusätzlich befinden sich auf dem Gutschein alle wichtigen Informationen, wie Einlösezeitraum, Gutscheinart und Gutscheincode.
              </p>
            </div>
            <div class="text-center">
              <i class="bi bi-gift text-6xl text-peach"></i>
              <p class="text-peach text-xl my-3">Gutschein verschenken</p>
              <p class="text-sm">
                Versende den Gutschein entweder von Wallet zu Wallet oder über WhatsApp & Co. Du kannst deinen persönlichen Gutschein aber auch als PDF herunterladen und ausdrucken. So hast du die Möglichkeit epasnets Gutscheine entweder digital oder klassisch zu verschenken.
              </p>
            </div>
          </div>

          <div class="text-center text-gray-500 mt-20 text-xl italic">
            <p class="text-gray-500">
              1 Euro von jedem Gutscheinkauf spendest du für soziale Projekte in deiner Region
            </p>
          </div>

          <div class="text-center">
            <a class="inline-block mt-6 px-4 py-2 rounded-md more-button font-semibold" href="#">
              MEHR ÜBER EPASNETS ERFAHREN → 
            </a>
          </div>

          <div class="mx-auto py-20 w-full">
            <h2 class="font-bold mb-4 text-3xl text-center text-peach">
              UNSERE ERLEBNISSE:
            </h2>
            <div class="flex flex-wrap gap-2 justify-center">
              <a
                v-for="(category, index) in CATEGORIES"
                :key="`item-${index}`"
                :href="`/vouchers/category/${$helpers.toSlug(category.name)}`"
                class="border border-peach flex font-medium hover:bg-peach hover:text-white items-center px-10 py-3"
              >
                <p>{{ category.name }}</p>
              </a>
            </div>
          </div>

          <div class="max-w-2xl mx-auto w-11/12">
            <h2 class="font-bold mb-4 text-3xl text-center text-peach">
              BEKANNT AUS:
            </h2>
             <div class="grid grid-cols-4 md:gap-4 lg:gap-7">
              <div class="pl-4 sm:px-0">
                <img src="@/_assets/img/epasnets-comp-1.webp" class="mb-2" />
              </div>

              <div class="pl-4 col-span-2 sm:px-0">
                <img src="@/_assets/img/epasnets-comp-2.webp" class="mb-2" />
              </div>

              <div class="pl-4 sm:px-0">
                <img src="@/_assets/img/epasnets-comp-3.webp" class="mb-2" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-10 company-bg-color">
        <div class="content-container w-11/12">
          <h2 class="text-center text-3xl mb-10 font-medium text-white">
            Über 200 Tiroler Unternehmen bei epasnets
          </h2>
          <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
            <div class="py-3">
              <img class="w-1/2 mx-auto" src="/images/companies/company-1-min.webp">
            </div>
            <div class="py-3">
              <img class="w-1/2 mx-auto" src="/images/companies/company-2-min.webp">
            </div>
            <div class="py-3">
              <img class="w-1/2 mx-auto" src="/images/companies/company-3-min.webp">
            </div>
            <div class="py-3">
              <img class="w-1/2 mx-auto" src="/images/companies/company-4-min.webp">
            </div>
            <div class="py-3">
              <img class="w-1/2 mx-auto" src="/images/companies/company-5-min.webp">
            </div>
            <div class="py-3">
              <img class="w-1/2 mx-auto" src="/images/companies/company-6-min.webp">
            </div>
            <div class="py-3">
              <img class="w-1/2 mx-auto" src="/images/companies/company-7-min.webp">
            </div>
            <div class="py-3">
              <img class="w-1/2 mx-auto" src="/images/companies/company-8-min.webp">
            </div>
            <div class="py-3">
              <img class="w-1/2 mx-auto" src="/images/companies/company-9-min.webp">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <SellerContent v-if="AUTH_USER.role.name === null" /> -->

    <div
      v-if="showAnnouncement"
      class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center"
      :class="showAnnouncement ? 'modal-active' : ''"
    >
      <div
        class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        @click="showAnnouncement = false"
      />
      <div
        class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
      >
        <div class="modal-content py-4 text-left px-6">
          <div class="flex justify-between items-center pb-3">
            <p class="text-xl font-bold">
              Schön dich bei epasnets zusehen!
            </p>
            <div
              class="modal-close cursor-pointer z-50"
              @click="showAnnouncement = false"
            >
              <svg
                class="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <p>
            Unsere Seite befindet sich derzeit in der ersten Testphase und wird
            laufend weiterentwickelt. Hol dir als Erster einen kostenlosen
            Zugang zum epasnets Onlineshop und profitiere von unseren Early-Bird
            Konditionen. Du kannst schon jetzt alle Funktionen nutzen und
            Gutscheine aus Tirol kaufen, personalisieren und verschenken oder
            einlösen.
          </p>
          <div class="flex justify-end pt-2">
            <a
              href="https://www.epasnets.com/register/buyer"
              class="px-4 bg-transparent p-3 rounded-lg text-peach mr-2 text-xs"
              >Jetzt als Käufer registrieren</a
            >
            <a
              href="https://verkaufen.epasnets.com"
              class="px-4 company-bg-color p-3 rounded-lg text-white text-xs"
              >Jetzt als Verkäufer registrieren</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VoucherList from '_components/List/Modules/VoucherList/';
import CategoryList from '_components/List/Modules/CategoryList/';
import GuestHeader from './GuestHeader';
import GuestContent from './GuestContent';
import SellerContent from '_pages/Home/shared/SellerContent';
import GuestFooter from './GuestFooter';
import { slider, slideritem } from 'vue-concise-slider';
import { get } from '_helpers/ApiService';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VouchCategory from '_components/VouchCategory';
import VoucherCard from '_components/List/Modules/VoucherList/VoucherCard/';

export default {
  components: {
    GuestHeader,
    GuestContent,
    SellerContent,
    GuestFooter,
    VoucherList,
    CategoryList,
    slider,
    slideritem,
    VueSlickCarousel,
    VouchCategory,
    VoucherCard
  },
  data() {
    return {
      section_1: process.env.VUE_APP_SECTION_1,
      section_2: process.env.VUE_APP_SECTION_2,
      section_3: process.env.VUE_APP_SECTION_3,
      section_4: process.env.VUE_APP_SECTION_4,
      section_5: process.env.VUE_APP_SECTION_5,
      section_6: process.env.VUE_APP_SECTION_6,
      section_7: process.env.VUE_APP_SECTION_7,
      section_8: process.env.VUE_APP_SECTION_8,
      section_9: process.env.VUE_APP_SECTION_9,
      params: {
        keyword: '',
        page: 1,
        paginate: 9,
        isNewest: false,
        isMostPopular: false,
        isLowestPrice: false,
        isPrice: null,
        isLoading: false,
        featured: true,
        seed: new Date().getTime(),
      },
      showAnnouncement: false,
      sliderOption: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        rows: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        draggable: false,
      },
      categoryOption: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        rows: 1,
        autoplay: false,
        arrows: false,
        draggable: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 760,
            settings: {
              arrows: false,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 640,
            settings: {
              arrows: false,
              slidesToShow: 1,
              centerMode: true
            },
          },
        ],
      },
      companyOptions: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        touchThreshold: 5,
        rows: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
      companies: [],
      categories: [
        {
          image: '/categories/wellness.webp',
          route: '/vouchers/category/wellness-&-gesundheit',
          name: 'Wellness',
        },
        {
          image: '/categories/adventure.webp',
          route: '/vouchers/category/sport-&-adventure',
          name: 'Adventure',
        },
        {
          image: '/categories/genuss.webp',
          route: '/vouchers/category/kurzurlaub',
          name: 'Genuss'
        },
        {
          image: '/categories/fitness.webp',
          route: '/vouchers/category/sport-&-adventure',
          name: 'Fitness',
        },
        {
          image: '/categories/tiere.webp',
          route: '/vouchers/category/tiere',
          name: 'Tiere'
        },
        {
          image: '/categories/kurse.webp',
          route: '/vouchers/category/kurse',
          name: 'Kurse'
        },
      ],
      voucher: [],
    };
  },
  computed: {
    AUTH_USER() {
      return this.$store.getters.AUTH_USER;
    },
    CATEGORIES() {
      return this.$store.getters.CATEGORIES;
    },
    // FEATURED_VOUCHERS() {
    //   return this.$store.getters.FEATURED_VOUCHERS;
    // },
    VOUCHERS() {
      return this.$store.getters.VOUCHERS;
    },
    IS_LOADING() {
      return this.$store.getters.IS_LOADING;
    },
    REGIONS() {
      return this.$store.getters.REGIONS;
    },
    // IS_LOAD_MORE()
    // {
    //   return this.$store.getters.IS_LOAD_MORE
    // },
  },
  // watch: {
  //   async IS_LOAD_MORE(newVal)
  //   {
  //     if( newVal ) {
  //       await this.onFetchData({
  //         ...this.params,
  //         page: this.params.page + 1
  //       })
  //       await this.$store.commit('SET_IS_LOAD_MORE', false)
  //     }
  //   },
  // },
  mounted() {
    (async () => {
      try {
        // await this.$store.commit('SET_IS_INFINITE_LOAD', true)
        await this.$store.commit('SET_IS_LOADING', { status: 'open' });
        await this.$store.commit('SET_VOUCHERS', []);
        // await this.$store.commit('SET_FEATURED_VOUCHERS', []);
        await this.$store.commit('SET_CATEGORIES', []);
        let { data } = await get('company/all');
        this.companies = data.companies.sort(() => Math.random() - 0.5);
        this.companies = this.companies.filter((company) => {
          return company.logo != null;
        });
        // await this.onFetchNewestVouchers();
        // await this.onFetchFeaturedVouchers()
        await this.onFetchCategories();

        let vouch1 = await this.$store.dispatch('FETCH_VOUCHER', {
          id: 2,
        });

        this.voucher = vouch1.voucher;

        await this.$store.commit('SET_IS_LOADING', { status: 'close' });
      } catch (err) {
        await this.$store.commit('SET_IS_LOADING', { status: 'close' });
      }
    })();

    setTimeout(() => {
      this.showAnnouncement = false;
      if (location.hash == '#categories') {
        var elmnt = document.getElementById('categories');
        elmnt.scrollIntoView();
      }
    }, 3000);
  },
  beforeDestroy() {
    (async () => {
      await this.$store.commit('SET_IS_INFINITE_LOAD', false);
    })();
  },
  methods: {
    async onPaginateVouchers(action) {
      let params = {
        ...this.params,
        page: action === 'prev' ? this.params.page - 1 : this.params.page + 1,
      };
      await this.$store.commit('SET_VOUCHERS', []);
      await this.onFetchData(params);
      document.querySelector('.voucher-list__container').scrollIntoView();
    },
    async onSearchData(data = null, action) {
      if (action == 'sort') {
        this.params.keyword = '';
      }
      let params =
        action == 'sort' || action == 'filter'
          ? {
              ...this.params,
              ...data,
              page: 1,
            }
          : {
              ...this.params,
              page: 1,
            };
      await this.$store.commit('SET_VOUCHERS', []);
      await this.onFetchData(params);
    },
    async onFetchData(data) {
      // await this.$store.commit('SET_IS_INFINITE_LOAD', true)
      await this.$store.commit('SET_IS_PROCESSING', { status: 'open' });
      this.params = {
        ...this.params,
        ...data,
      };
      await this.onFetchNewestVouchers();
      await this.$store.commit('SET_IS_PROCESSING', { status: 'close' });
    },
    async onFetchNewestVouchers() {
      try {
        const data = await this.$store.dispatch(
          'FETCH_SEARCH_VOUCHERS',
          this.params
        );
        if (data.vouchers.next_page_url == null) {
          await this.$store.commit('SET_IS_INFINITE_LOAD', false);
        }
      } catch (err) {
        console.log('err', err);
      }
    },
    async onFetchCategories() {
      try {
        await this.$store.dispatch('FETCH_CATEGORIES');
      } catch (err) {
        console.log('err', err);
      }
    },
    // async onFetchFeaturedVouchers() {
    //   try {
    //     await this.$store.dispatch('FETCH_FEATURED_VOUCHERS');
    //   } catch (err) {
    //     console.log('err', err);
    //   }
    // },
    onSetImage(value) {
      return value.search('base64') < 0
        ? `${process.env.VUE_APP_API_BASE_URL}/storage/${value}`
        : value;
    },
  },
};
</script>
<style lang="css" scoped>
.valentines {
  background: #fcb6be 0% 0% no-repeat padding-box;
}
.text-logo {
  font-size: 25px;
}

@screen lg {
  .text-logo {
    font-size: 55px;
  }
}
.border-separator {
  @apply border-b border-input-border my-16;
}
.guest-homepage >>> .slick-list[style] {
  padding: 0 20% 0 0 !important;
}

.category-wrapper {
  width: 100%;
  max-width: 200px;
}
.catgory-image {
  width: 100%;
  max-width: 180px;
}
.vouch-category .slick-slide {
  padding: 0 10px;
}
.vouch-category .slick-prev:before,
.vouch-category .slick-next:before {
  font-family: inherit;
  font-size: 45px;
  color: #ff5563;
}
.vouch-category .slick-prev:before {
  margin-left: -15px;
}
.vouch-category .slick-list {
  padding-bottom: 15px;
}
@media only screen and (max-width: 600px) {
  #guest-voucher-list >>> .voucher-card-container {
    width: 100%;
    max-width: unset;
  }
}
</style>
