var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.bgColor,
    _vm.theme,
    { 'py-5 md:py-10' : !_vm.isPlugin }
  ]},[_c('div',{staticClass:"content-container w-11/12",class:[
      { 'mt-5 md:mt-10' : !_vm.isPlugin }
    ]},[_c('div',{staticClass:"flex flex-col gap-6 md:flex-row",class:[
        { 'mb-6' : !_vm.isMinimal }
      ]},[_c('div',{staticClass:"flex-1 text-center md:text-left",class:[
          { 'hidden' : _vm.hideTitle }
        ]},[_c('h2',{staticClass:"font-bold text-3xl md:text-5xl",class:_vm.titleClass || _vm.fontColor},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('span',{staticClass:"description font-semibold inline-block md:text-xl text-black text-lg",class:_vm.descriptionClass || 'description'},[(!_vm.isVoucher)?_c('span',[_vm._v(" "+_vm._s(_vm.description)+" ")]):_c('span',[_vm._v(" Hard Rock Café Innsbruck "),_c('br'),_c('br'),_vm._v(" Kulinarik & Shopping im Herzen der Tiroler Alpen – das Hard Rock Café Innsbruck bietet ein unvergessliches Erlebnis, mit genialen Speisen, sensationellen Drinks und Live-Events der Extraklasse. Und das Hard Rock Café T-Shirt Innsbruck gehört auch in deinen Kleiderschrank. ")])])]),_vm._t("default")],2),_c('div',{staticClass:"vouch-category"},[(!_vm.isMinimal)?_c('span',{staticClass:"block company-color font-semibold mb-6 text-2xl text-xl",class:_vm.fontColor},[_vm._v(" "+_vm._s(_vm.preTitle || 'Lust auf mehr?')+" ")]):_vm._e(),(_vm.vouchers.length && !_vm.loading)?_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.voucherOption,false),_vm._l((_vm.vouchers),function(voucher,index){return _c('VoucherCard',{key:("voucher-" + index),attrs:{"voucher":voucher,"showRegion":false,"openNewTab":true,"showCartButton":true,"isPlugin":_vm.isPlugin}})}),1):_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrapper-cell"},[_c('div',{staticClass:"image"}),_c('div',{staticClass:"text"},[_c('div',{staticClass:"text-line"}),_c('div',{staticClass:"text-line"}),_c('div',{staticClass:"text-line"}),_c('div',{staticClass:"text-line"})])])])],1),_c('div',{staticClass:"mt-5 mx-auto w-full justify-center flex"},[(_vm.showButton)?_c('a',{staticClass:"bg-white font-medium more-button mt-6 px-5 py-2 rounded-md text-black",attrs:{"href":("/gutscheine/category/" + (_vm.$helpers.toSlug(_vm.category)))}},[_vm._v(" ZU ALLEN GUTSCHEINEN → ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }