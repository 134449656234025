<template>
  <div
    :class="[
      bgColor,
      theme,
      { 'py-5 md:py-10' : !isPlugin }
    ]"
  >
    <div
      class="content-container w-11/12"
      :class="[
        { 'mt-5 md:mt-10' : !isPlugin }
      ]"
    >
      <div
        class="flex flex-col gap-6 md:flex-row"
        :class="[
          { 'mb-6' : !isMinimal }
        ]"
      >
        <div
          class="flex-1 text-center md:text-left"
          :class="[
            { 'hidden' : hideTitle }
          ]"
        >
          <h2 class="font-bold text-3xl md:text-5xl" :class="titleClass || fontColor">
            {{ title }}
          </h2>
          <span
            class="description font-semibold inline-block md:text-xl text-black text-lg"
            :class="descriptionClass || 'description'"
          >
            <span v-if="!isVoucher">
              {{ description }}
            </span> 
            <span v-else>
              Hard Rock Café Innsbruck <br/><br/> Kulinarik & Shopping im Herzen der Tiroler Alpen – das Hard Rock Café Innsbruck bietet ein unvergessliches Erlebnis, mit genialen Speisen, sensationellen Drinks und Live-Events der Extraklasse. Und das Hard Rock Café T-Shirt Innsbruck gehört auch in deinen Kleiderschrank.
            </span>
          </span>
        </div>
        <slot></slot>
      </div>
      <div class="vouch-category">
        <span 
          class="block company-color font-semibold mb-6 text-2xl text-xl" :class="fontColor"
          v-if="!isMinimal"
        >
          {{ preTitle || 'Lust auf mehr?' }}
        </span>
        <VueSlickCarousel
          v-bind="voucherOption"
          v-if="vouchers.length && !loading"
        >
          <VoucherCard
            v-for="(voucher, index) in vouchers"
            :key="`voucher-${index}`"
            :voucher="voucher"
            :showRegion="false"
            :openNewTab="true"
            :showCartButton="true"
            :isPlugin="isPlugin"
          />
        </VueSlickCarousel>
        <div class="wrapper" v-else>
          <div class="wrapper-cell">
            <div class="image"></div>
            <div class="text">
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 mx-auto w-full justify-center flex">
        <a
          v-if="showButton"
          :href="`/gutscheine/category/${$helpers.toSlug(category)}`"
          class="bg-white font-medium more-button mt-6 px-5 py-2 rounded-md text-black"
        >
          ZU ALLEN GUTSCHEINEN →
        </a>
      </div>
    </div>
  </div>
</template>
<script src="https://player.vimeo.com/api/player.js"></script>
<script>
import VoucherCard from '_components/List/Modules/VoucherList/VoucherCard/';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  components: {
    VoucherCard,
    VueSlickCarousel,
  },
  props: [
    'title',
    'description',
    'category',
    'theme',
    'ids',
    'showButton',
    'preTitle',
    'titleClass',
    'descriptionClass',
    'isVoucher',
    'isMinimal',
    'hideTitle',
    'isPlugin'
  ],
  data() {
    return {
      loading: true,
      vouchers: [],
      categorySearch: this.category,
      voucherOption: {
        arrows: true,
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        touchThreshold: 5,
        rows: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        draggable: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              arrows: false,
              centerPadding: '20px',
            },
          },
        ],
      },
    };
  },
  computed: {
    fontColor() {
      let font = {
        peach: 'text-white',
      };
      return font[this.theme] || 'company-color';
    },
    bgColor() {
      let bg = {
        peach: 'company-bg-color',
        gray: 'gray',
      };
      return bg[this.theme] || 'bg-white';
    },
  },
  async mounted() {
    let ids = this.ids.split(',');
    let params = {
      page: 1,
      paginate: ids.length,
      isNewest: false,
      isLowestPrice: false,
      isMostPopular: false,
      isRegion: [],
      isPrice: [],
      targets: [],
      seasons: [],
      subcategory: [],
      ids: ids,
    };
    const data = await this.$store.dispatch('FETCH_SEARCH_VOUCHERS', params);
    this.vouchers = data.vouchers.data;
    this.loading = false;
  },
};
</script>
<style>
.more-button {
  background: #fff;
}
.gray {
  background: #ededed;
}
.peach .more-button {
  background: white;
  color: #ff5563;
}
.peach .description {
  color: white;
}
.gray .description {
  color: #000 !important;
}
.vouch-category .slick-slide {
  padding: 0 10px;
}
.vouch-category .slick-prev:before,
.vouch-category .slick-next:before {
  font-family: inherit;
  font-size: 45px;
  color: #ff5563;
}

.peach .vouch-category .slick-prev:before,
.peach .vouch-category .slick-next:before {
  color: white;
}
.vouch-category .slick-prev:before {
  margin-left: -15px;
}
.vouch-category .slick-list {
  padding-bottom: 15px;
}

.wrapper-cell {
  display: flex;
  margin-bottom: 30px;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background,
.text-line,
.image {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.image {
  height: 400px;
  width: 100%;
}
</style>
