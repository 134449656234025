<template>
  <MainLayout contentStyle="background: #fff;">
    <template #content>
      <div class="flex flex-col w-full">
        <GuestHome
          v-if="AUTH_USER.role.name === null || AUTH_USER.role.name === 'user'"
        />
      </div>
    </template>
  </MainLayout>
</template>
<script>
  import MainLayout from '_layouts';
  import GuestHome from './Temp/Guest/'

  export default {
    name: 'Home',
    components: {
      MainLayout,
      GuestHome,
    },
    data() {
      return {
        role: null,
        isLoading: true,
      }
    },
    computed: {
      AUTH_USER()
      {
        return this.$store.getters.AUTH_USER
      }
    },
    watch: {
    },
    mounted() {

    },
    methods: {
    }
  }
</script>
<style lang='css' scoped>
</style>