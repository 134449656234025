<template>
  <div class="-mt-10">
    <div class="bg-gray-300 flex guest-header items-center justify-center overflow-hidden relative">
      <div
        class="content-container flex flex-col h-96 header-container items-center justify-center py-80 relative w-full z-30"
      >
        <div
          class="bg-opacity-75 bg-white flex flex-col items-center lg:py-10 max-w-2xl md:py-8 py-6 search-box w-11/12"
        >
          <h1 class="font-medium lg:text-4xl mb-1 md:text-3xl text-center text-peach text-xl">
            Finde deine Tiroler Momente:
          </h1>
          <div
            class="bg-opacity-75 bg-peach flex flex-col lg:w-9/12 mt-2.5 pt-4 pb-2 rounded-xl w-11/12"
          >
            <SearchInputField
              id="search-voucher"
              class="w-full"
              font="text-xs lg:text-sm"
              placeholder="Wonach suchst Du?"
              :submitButton="false"
              :iconLeft="true"
            />
            <div class="w-full">
              <SelectField
                id="search-location"
                class="w-11/12 max-w-md mx-auto bg-white p-1 rounded md:w-full"
                :placeholder="'In welcher Region?'"
                :isMinimal="true"
              />
            </div>
            <div class="w-11/12 mx-auto flex justify-center pb-2">
              <button
                class="bg-white w-full max-w-md py-3 rounded text-sm text-peach uppercase font-semibold border-white hover:fo hover:bg-opacity-75"
                @click="onSearchButtonClick"
              >
                Suchen
              </button>
            </div>
          </div>
        </div>
      </div>

      <video
        autoplay
        loop
        muted
        class="absolute z-10 w-auto min-w-full min-h-full max-w-none"
      >
        <source
          src="@/_assets/img/epasnets-video-header-min.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>
<script>
import SearchInputField from '_components/Form/SearchInputField';
import SelectField from '_components/Form/SelectField';

export default {
  components: {
    SearchInputField,
    SelectField,
  },
  created() {},
  methods: {
    onSearchButtonClick() {
      this.$router.push('/vouchers/search?keyword=hello&region=region')
    }
  },
};
</script>
<style lang="css" scoped>
.guest-header {
  /* margin-bottom: calc(var(--bottomSpacing)); */
}
.search-box {
  bottom: calc(var(--bottomSpacing) * -1);
  border-radius: 20px;
}
.search-box >>> .input-field {
  @apply bg-white;
}
.search-box__details {
  @apply flex flex-wrap pt-2 justify-around;
}
.search-box__details-item {
  @apply flex flex-col items-center;
}
.icon {
  @apply h-6 w-6 text-peach mb-1;
}
</style>
