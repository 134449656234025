<template>
  <div class="bg-dirty">
    <div class="content-container header-footer flex flex-col items-center px-8">
      <div class="flex flex-row flex-wrap w-full py-2 mt-4 font-bold">
        <div class="font-body text-xs p-2 sm:text-base w-1/2 lg:w-1/4">
          <a 
            class="text-peach" 
            href="https://verkaufen.epasnets.com/impressum" 
            target="_blank"
          >
            Impressum
          </a>
        </div>
        <div class="font-body text-xs p-2 sm:text-base w-1/2 lg:w-1/4">
          <a 
            class="text-peach" 
            href="https://verkaufen.epasnets.com/agb" 
            target="_blank"
          >
            AGB
          </a>
        </div>
        <div class="font-body text-xs p-2 sm:text-base w-1/2 lg:w-1/4">
          <a 
            class="text-peach" 
            href="https://verkaufen.epasnets.com/datenschutz" 
            target="_blank"
          >
            Datenschutzerklärung
          </a>
        </div>
        <div class="font-body text-xs p-2 sm:text-base w-1/2 lg:w-1/4">
          <a 
            class="text-peach" 
            href="https://verkaufen.epasnets.com" 
            target="_blank"
          >
            Partner werden
          </a>
        </div>
      </div>
      <div class="text-center px-2 py-4">
        &copy; 2020 Epasnets Gmbh
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    components: {
    },
    data() {
      return {
      }
    },
    created() {
    },
    methods: {
    }
  }
</script>
<style lang="css" scoped>
  .header-footer {
    justify-content: center;
    text-align: center;
  }
</style>